import '../css/Accordion.css'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionComp({title, details, link, setIframeLink, setChosenWebsite, setWebsiteInfo}){
    return (
        <Accordion className='accordion' onClick={() => {setIframeLink(link); setChosenWebsite(title); setWebsiteInfo(details)}}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className='accordion-header'
            style={{color: 'white'}}
            >
            {title}
            </AccordionSummary>
            <AccordionDetails>
                <a style={{textDecoration: 'underline', color: 'black'}} href={link}>Link to website</a>
            </AccordionDetails>
        </Accordion>
    )
}
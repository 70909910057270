import * as React from 'react';
import '../css/Navbar2.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ComputerIcon from '@mui/icons-material/Computer';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import hadi_karaki from '../assets/hadikaraki.PNG';
import AccordionComp from './AccordionComp'

export default function Navbar2({ setIframeLink, setChosenWebsite }) {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
  
    const handleDrawerOpen = () => {
      setDrawerOpen(true);
    };
  
    const handleDrawerClose = () => {
      setDrawerOpen(false);
    };
  
    return (
      <nav key="nav">
        <AppBar key="app-bar" position="static" sx={{ margin: 0, boxShadow: 'none', backgroundColor: '#2c3e50' }}>
            <Toolbar id="nav-toolbar" style={{ width: '97.5%', margin: '0 auto', display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
                id='menu-icon'
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>

              {/* Centered Logo and Typography for Mobile View */}
              <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                {/* <ComputerIcon sx={{ color: 'white', mr: 1 }} /> */}
                <Typography
                  key="typography2"
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  HADI KARAKI
                </Typography>
              </Box>

              {/* Full Logo and Typography for Desktop View */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                <ComputerIcon sx={{ mr: 1, color: 'white' }} />
                <Typography
                  key="typography1"
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'white',
                    textDecoration: 'none',
                  }}
                >
                  HADI KARAKI
                </Typography>
              </Box>

              {/* LinkedIn and Avatar */}
              <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', flexGrow: 1 }}>
                <LinkedInIcon />
                <a href="https://www.linkedin.com/in/hadi-karaki-375991214/" style={{ textDecoration: 'none' }}>
                  <Button key="linkedin" sx={{ my: 2, color: 'white', display: 'block' }}>
                    LinkedIn
                  </Button>
                </a>
              </Box>
              
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip>
                  <IconButton sx={{ p: 0 }}>
                    <Avatar alt="Hadi Karaki" src={hadi_karaki} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Toolbar>
          </AppBar>

  
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: '260px',
                backgroundColor: '#2c3e50',
                padding: '20px 10px',
              },
            }}
          >
            <Button
              onClick={handleDrawerClose}
              sx={{
                color: 'white',
                textTransform: 'none',
                alignSelf: 'flex-end',
                mb: 2,
              }}
            >
              Close
            </Button>
            <Typography
              variant="h5"
              sx={{
                ml: 2,
                mt: 0,
                mb: 2,
                color: 'white',
                textDecoration: 'underline',
                fontWeight: '700',
              }}
            >
              Projects
            </Typography>
            <List sx={{ ml: 2 }}>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <AccordionComp
                  title="Pharmacy website"
                  link="https://pharmacy.hadikaraki.net"
                  setIframeLink={setIframeLink}
                  setChosenWebsite={setChosenWebsite}
                />
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <AccordionComp
                  title="Delivery website"
                  link="https://delivery.hadikaraki.net"
                  setIframeLink={setIframeLink}
                  setChosenWebsite={setChosenWebsite}
                />
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <AccordionComp
                  title="Campsites website"
                  link="https://campsite.hadikaraki.net"
                  setIframeLink={setIframeLink}
                  setChosenWebsite={setChosenWebsite}
                />
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <AccordionComp
                  title="Ecommerce website"
                  link="https://ecommerce.hadikaraki.net"
                  setIframeLink={setIframeLink}
                  setChosenWebsite={setChosenWebsite}
                />
              </ListItem>
            </List>

            <Typography
              variant="h5"
              sx={{
                ml: 2,
                mt: 4,
                mb: 2,
                color: 'white',
                textDecoration: 'underline',
                fontWeight: '700',
              }}
            >
              Skills
            </Typography>
            <List sx={{ ml: 2 }}>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>AJAX</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Docker</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Kubernetes</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Linux</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Scripting</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Nginx</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>MongoDB</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>PHP</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Express</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Node</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>React</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>HTML & CSS</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>MERN stack</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>Java</span>
              </ListItem>
              <ListItem disablePadding sx={{ mb: 1 }}>
                <span style={{ color: 'white' }}>JavaScript</span>
              </ListItem>
            </List>
            <div className="resume-btn" style={{marginLeft: '15px'}}>
              <button className="download-button">
                <a href="/resume.pdf" download="resume.pdf">Download Resume</a>
              </button>
            </div>
            <div className="linkedin-link">
              <LinkedInIcon/>
              <a href="https://www.linkedin.com/in/hadi-karaki-375991214/">LinkedIn</a>
            </div>
          </Drawer>
      </nav>
    );
  }
  
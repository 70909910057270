import './css/App.css';
import Navbar2 from './components/Navbar2'
import AccordionComp from './components/AccordionComp'
import { useState } from "react";
import web_cert from "./assets/web-dev-cert.jpg"
import dev_cert from "./assets/devops-cert.jpg"
import ids_cert from "./assets/ids-cert.PNG"

function App() {
  const [iframeLink, setIframeLink] = useState('https://delivery.hadikaraki.net');
  const [chosenWebsite, setChosenWebsite] = useState('Delivery website');
  const [websiteInfo, setWebsiteInfo] = useState('A website intended to sell pharmaceutical products. Developed using NodeJS, Express and MongoDB as back-end, and React.js as front-end');

  return (
    <div className="App">
      <Navbar2 setIframeLink={setIframeLink} setChosenWebsite={setChosenWebsite} />
      <div className="content">
      <section className='left-side'>
          <div className='accordions-div'>
            <h2 className='section-title'>Projects</h2>
            <AccordionComp 
            title="Pharmacy website" 
            link="https://pharmacy.hadikaraki.net"
            details="A website intended to sell pharmaceutical products. Developed using NodeJS, 
                    Express and MongoDB as back-end, and React.js as front-end"
            setIframeLink={setIframeLink} 
            setChosenWebsite={setChosenWebsite}
            setWebsiteInfo={setWebsiteInfo}
            />
            <AccordionComp 
            title="Delivery website" 
            link="https://delivery.hadikaraki.net"
            details="A delivery service website where restaurants can sell their products. Developed using 
                    NodeJS, Express, and MongoDB as back-end, and React.js as front-end"
            setIframeLink={setIframeLink}
            setChosenWebsite={setChosenWebsite}
            setWebsiteInfo={setWebsiteInfo}/>
            <AccordionComp 
            title="Campsites website" 
            link="https://campsite.hadikaraki.net"
            details="A website project for the purpose of displaying campsites around the world. 
                    Developed using NodeJS, Express, MongoDB, and Mapbox API"
            setIframeLink={setIframeLink} 
            setChosenWebsite={setChosenWebsite}
            setWebsiteInfo={setWebsiteInfo}/>
            <AccordionComp 
            title="Ecommerce website" 
            link="https://ecommerce.hadikaraki.net"
            details="An E-commerce website for selling electronics. Developed using NodeJS, Express, 
                    and MongoDB"
            setIframeLink={setIframeLink} 
            setChosenWebsite={setChosenWebsite}
            setWebsiteInfo={setWebsiteInfo}/>
          </div>
          <div className='skills-div'>
            <h2 className='section-title' style={{paddingLeft: '4px'}}>Skills</h2>
            <ul className='skills-list'>
              <li>AJAX</li>
              <li>Docker</li>
              <li>Kubernetes</li>
              <li>Linux</li>
              <li>Scripting</li>
              <li>Nginx</li>
              <li>MongoDB</li>
              <li>PHP</li>
              <li>Express</li>
              <li>Node.js</li>
              <li>React</li>
              <li>HTML & CSS</li>
              <li>MERN stack</li>
              <li>Java</li>
              <li>JavaScript</li>
            </ul>
          </div>
          <div className="resume-btn">
            <button className="download-button">
              <a href="/resume.pdf" download="resume.pdf">Download Resume</a>
            </button>
          </div>
        </section>
        <section className='right-side'>
          <div className='about'>
            <h1 style={{textDecoration: 'underline'}}>About</h1>
            <p style={{fontSize: '17px'}}>Aspiring Full Stack Web Developer with hands-on experience building full-featured web applications using React, Node.js, and MongoDB. Completed several personal projects that demonstrate a solid understanding of modern web development practices, including responsive design, RESTful APIs, and database management. Proficient in DevOps tools like Docker and Kubernetes, with practical experience in deploying and managing applications in Linux environments using Nginx. Eager to apply my skills in a professional setting to contribute to impactful projects and continue growing in the field.</p>
          </div>
          <div className='website'>
            <h1 style={{textDecoration: 'underline'}}>{chosenWebsite}</h1>
            <span style={{color: 'grey'}}>Choose a website on the left menu</span>
            <div className='website-grid'>
              <div className='websiteinfo-div'>
                <p>{websiteInfo}</p>
              </div>
              <div className='iframe-div'>
                <iframe src={iframeLink} title="W3Schools Free Online Web Tutorials"></iframe>
              </div>
            </div>
          </div>
          <div className='certificates'>
          <h1 style={{textDecoration: 'underline'}}>Certificates</h1>
            <div class="certificate-grid">
                <div class="certificate-item">
                    <a href="https://www.udemy.com/certificate/UC-e8617e84-1504-4e05-b2d5-d235ef861748/">
                        <img src={web_cert} alt="Udemy certificate image"/>
                    </a>
                </div>
                <div class="certificate-item">
                    <a href="">
                        <img src={dev_cert} alt="IDS certificate image"/>
                    </a>
                </div>
                <div class="certificate-item">
                    <a href="">
                        <img src={ids_cert} alt="IDS certificate image"/>
                    </a>
                </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
